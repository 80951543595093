/**
 * Dependencies
 */
import {polaris} from "./config";
import {submitForm, websiteNav, constant, loadPage} from "./core";


/**
 * Global variables
 */
let   localStorage = window.localStorage;
const partials     = document.querySelector(".partials");


/**
 * Public Page APIs
 */
export const pageAPIs = () => {
    // Acive toggle for menu plugin
    websiteNav('.menu-plugin', 'menu--active');

    // Load more content
    loadMore();

    // Afterload plugin
    afterLoad();

    // Update SEO
    updateSEO();

    // Blog gallery splide
    blogGallerySplide();

    // Wheather widget
    weatherWidget();
};


/**
 * @desc Loads more content by JavaScript after clicking a button (BLOG)
 * 
 * @return void
 */
const loadMore = () => {
    const loadMoreBtns = document.querySelectorAll('.load-more');
    if (loadMoreBtns.length > 0) {
        loadMoreBtns.forEach(btn => {
            btn.onclick = () => {
                // Query requirements
                const id     = btn.dataset.id;
                const qty    = btn.dataset.qty;
                const offset = btn.dataset.offset;
                const url    = btn.dataset.url;
                const api    = partials.dataset.api;

                // Query selector
                const btnText = btn.innerHTML;

                // Prepare the button
                btn.setAttribute("disabled", true);
                btn.innerHTML = constant("Requesting...");

                // Fetch more content
                fetch(api, {
                    method: 'put',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({load_more: true, id: id, offset: offset, url: url})
                })
                .then(response => response.json())
                .then(result => {
                    // Everything is fine
                    if (result.status === "success") {
                        // Find container
                        const moreContainer = document.querySelector(`.load-more-container[data-id="${id}"]`);

                        // Append temporary content
                        polaris.append('div', moreContainer, "", [], `temp-${id}`);

                        // Add real content
                        moreContainer.querySelector(`#temp-${id}`).outerHTML = result.content;

                        // Update button offset
                        if (result.remain) btn.dataset.offset = Number(offset) + Number(qty);

                        // SPA reload
                        const app    = partials.dataset.app;
                        const source = partials.dataset.source;
                        loadPage(app, source, api, () => customAPIs(), false);
                    }

                    // An error occoured
                    else {
                        polaris.alert(constant('Something went wrong!'), "fadeInTop", "fadeOutBottom", "warning", alert, true, 750, 0);
                    }

                    // Content remained
                    if (result.remain) {
                        // Reset the button
                        btn.removeAttribute("disabled");
                        btn.innerHTML = btnText;
                    }
                    // Content not remained
                    else {
                        btn.style.display = "none";
                    }
                });

            };
        });
    }
}


/**
 * @desc Updates page SEO dynamicaly
 * 
 * @return void
 */
const updateSEO = () => {
    const selector = document.querySelector('.update-seo');
    if (selector) {
        const title       = document.querySelector("title");
        const description = document.querySelector('meta[name="description"]');
        const keywords    = document.querySelector('meta[name="keywords"]');

        // Update page SEO
        if (selector.dataset.title)       title.innerHTML = selector.dataset.title;
        if (selector.dataset.description) description.setAttribute("content", selector.dataset.description);
        if (selector.dataset.keywords)    keywords.setAttribute("content", selector.dataset.keywords);
    }
}


/**
 * @desc Loads plugin data after page fully loaded
 * 
 * @return void
 */
const afterLoad = async () => {
    const plugins = document.querySelectorAll('.afterload');
    if (plugins.length > 0) {
        const app     = partials.dataset.app;
        const source  = partials.dataset.source;
        const api     = partials.dataset.api;
        const fetches = [];

        // Append plugin id
        plugins.forEach(plugin => {
            // Fetch plugins content
            const id  = plugin.dataset.id;
            const url = plugin.dataset.url;
            fetches.push(
                fetch(api, {
                    method: 'put',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({after_load: true, id: id, url: url})
                })
                .then(response => response.json())
                .then(result => {
                    // Everything is fine
                    if (result.status === "success") {
                        // Add real content
                        plugin.outerHTML = result.content;
                        
                        // Update SEO
                        updateSEO();
                    }
    
                    // An error occoured 'Plugin Afterload Error!'
                    else {
                        plugin.outerHTML = '<a href="/ku/" class="spa-link">Home Page</a>';
                    }
                })
            )
        });

        // Reload required libraries after all fetches settled
        await Promise.allSettled(fetches).then(() => {
            // App APIs reload
            blogGallerySplide();

            // Load more content
            loadMore();

            // SPA reload
            loadPage(app, source, api, () => customAPIs(), false);
        });
    }
}


/**
 * @desc Loads blog gallery splide
 * 
 * @return void
 */
const blogGallerySplide = () => {
    /**
     * Gallery Splide
     */
    if (document.querySelector('#blog-gallery-main') && document.querySelector('#blog-gallery-tmb')) {
        const splideMain = new Splide('#blog-gallery-main', {
            "type": "fade",
            "drag": false,
            "pagination": false,
            "arrows": false
        });

        const splideTmb = new Splide('#blog-gallery-tmb', {
            "skin": "auto",
            "fixedWidth": 140,
            "fixedHeight": 80,
            "gap" : 2,
            "rewind": true,
            "autoplay": true,
            "interval": 6000,
            "speed": 1000,
            "drag": true,
            "arrows": false,
            "pagination": false,
            "isNavigation": true,
            "direction": partials.dataset.dir
        });

        splideMain.sync(splideTmb);
        splideMain.mount();
        splideTmb.mount();
    }
}


/**
 * Wheather widget
 * link-1: https://weatherwidget.io/
 * link-2: https://forecast7.com/en/
 * link-3: https://www.youtube.com/results?search_query=autocomplete+location+input+for+weather
 */
const weatherWidget = () => {
    // Handle weather toggle
    const weatherToggler = document.querySelector("#weather-toggler");
    const weatherCities  = document.querySelectorAll("#weather-toggle div");

    // Check requirements
    if (weatherToggler && weatherCities.length) {
        // Init weather widget
        weatherWidgetInit();
    
        // Toggler
        weatherToggler.onchange = function() {
            // Cities
            weatherCities.forEach(city => {
                if (city.dataset.city == this.value) {
                    city.classList.remove("display-none");
                } else {
                    city.classList.add("display-none");
                }
            });
        };
    }
};
